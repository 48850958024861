/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
// react-router-dom components
/* import { Link } from "react-router-dom";
import { FormControl } from "@mui/material"; */
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.jpg";
import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";
import { AuthContext } from "../../../../auth/AuthContext";
import BasicForm from "./form";
import withReactContent from "sweetalert2-react-content";
const MySwal = withReactContent(Swal);

function LoginPage() {
  const { login, register, loginAgent, callCenter } = useContext(AuthContext);
  // const [rememberMe, setRememberMe] = useState(false);

  // const handleSetRememberMe = () => setRememberMe(!rememberMe);

  const [form, setForm] = useState({
    email: "",
    password: "",
    rememberme: false,
  });

  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setForm((form) => ({
        ...form,
        email,
        rememberme: true,
      }));
    }
  }, []);

  const onChangeOption = (e) => {
    const { value: option } = e.target;
    setForm((lastValues) => ({ ...lastValues, optionLogin: option }));
  };
  const submit = async (values, actions) => {
     /* values.rememberMe
      ? localStorage.setItem("email", values.email)
      : localStorage.removeItem("email"); */
          
    if(values.optionLogin == "admin" ){
      const { ok, msg, usuario } = await login(values);
      if (ok) {
        localStorage.setItem("name", usuario.nombre);
        localStorage.setItem("agent", usuario.agent);
        return Swal.fire({
          title: '¡Bienvenido...!', 
          html: `Acceso concedido a:<p>
              <ul style="text-align: left; ">
                <li>Nombre:${usuario.nombre}</li>
                <li>Extensión:${usuario.agent}</li>
                <li>correo:${usuario.email}</li>
              </ul>
                `,
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer')
          }
        });
      } else {        
        return Swal.fire("Error", msg, "error");
      }
    }

    if(values.optionLogin == "notAgent" ){
      const { ok, msg, usuario } = await callCenter(values);
      if (ok) {
        localStorage.setItem("name", usuario.nombre);
        localStorage.setItem("agent", usuario.agent);
        return Swal.fire({
          title: `¡Bienvenido al Call Center de: ${usuario.callcenter}!`, 
          html: `Acceso concedido a: ${usuario.nombre} de ${usuario.group}, asignado a la ext: ${usuario.agent} <p>
                `,
          timer: 5000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer) {
            console.log('I was closed by the timer')
          }
        });
      } else {        
        return Swal.fire("Error", msg, "error");
      }
    }
        
    if(values.optionLogin == "isAgent"){
      const { ok, msg, usuario } = await loginAgent(values);
      
      if(!ok){        
        return Swal.fire("Error, revisa la información", msg, "error");
      }
      
      if(usuario?.isUser){
        MySwal.fire({
          title: 'Escriba su contraseña',
          input: 'password',
          inputAttributes: {
            autocapitalize: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Entrar al sistema',
          showLoaderOnConfirm: true,
          preConfirm: (password) => {
            values.password = password;
            return login(values)
              .then(response => {
                if (!response.ok) {
                  throw new Error(`Su contraseña no coincide con nuestros registros, favor de reportarlo a soporte@iurdsys.net`)
                }
                return response;
              })
              .catch(error => {
                Swal.showValidationMessage(`${error}` )
              })
          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            
            localStorage.setItem("name", usuario.nombre);
            localStorage.setItem("agent", usuario.agent);
            Swal.fire({
              title: '¡Bienvenido...!', 
              html: `Acceso concedido a:<p>
                  <ul style="text-align: left; ">
                    <li>Nombre:${usuario.nombre}</li>
                    <li>Extensión:${usuario.agent}</li>
                    <li>correo:${usuario.email}</li>
                  </ul>
                    `,
              timer: 5000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
              }
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
              }
            });
          }
        })        
      }
      
      if(!usuario?.isUser && usuario.optionLogin == "isAgent" ){
        MySwal.fire({
          title: '¡Favor de Crear una contraseña!',
          // html: `Favor de recordar esta contraseña, para poder iniciar sesión en este sistema.`,
          html: `
          <input autocomplete="off" class="swal2-input" id="password1" type="password" placeholder="Escriba su contraseña..." /><br />
          <input autocomplete="off" class="swal2-input" id="password2" type="password" placeholder="Confirme su contraseña..." />
          `,
          focusConfirm: false,
          inputAttributes: {
            autocapitalize: 'off',
            autocomplete: 'off'
          },
          showCancelButton: true,
          confirmButtonText: 'Entrar al sistema',
          showLoaderOnConfirm: true,
          preConfirm: (password) => {
            const password1 = Swal.getPopup().querySelector('#password1').value;
            const password2 = Swal.getPopup().querySelector('#password2').value;
            if(password1 == password2){
              return register( usuario.nombre,  usuario.email, usuario.agent , password1 )
              .then(response => {
                if (!response) {
                  throw new Error(`Hubo un error al crear el usuario, favor de reportarlo a soporte@iurdsys.net`)
                }
                return response;
              })
              .catch(error => {
                Swal.showValidationMessage(error)
              })
            }else{
              Swal.showValidationMessage(`Las contraseñas no son iguales, favor de validar...`);
            }

          },
          allowOutsideClick: () => !Swal.isLoading()
        }).then((result) => {
          if (result.isConfirmed) {
            Swal.fire({
              title: '¡Bienvenido...!', 
              html: `Acceso concedido a:<p>
                  <ul style="text-align: left; ">
                    <li>Nombre:${usuario.nombre}</li>
                    <li>Extensión:${usuario.agent}</li>
                    <li>correo:${usuario.email}</li>
                  </ul>
                    `,
              timer: 5000,
              timerProgressBar: true,
              didOpen: () => {
                Swal.showLoading()
                const b = Swal.getHtmlContainer().querySelector('b')
              }
            }).then((result) => {
              if (result.dismiss === Swal.DismissReason.timer) {
                console.log('I was closed by the timer')
              }
            });
          } 
        })        
      }      
    }
    actions.setSubmitting(false);
  };
  const validation = (values) => {
    const errors = {};
    /*     if (!values.email) {
      errors.email = "email is required!";
    } else if (values.email.length <= 1) {
      errors.name = "Name has to be 1 character at less!";
    }
    if (!values.password) {
      errors.password = "password is required!";
    } */
    return errors;
  };

  return (
    <BasicLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="info"
          mx={2}
          mt={-3}
          p={2}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Acceso
          </MDTypography>
        </MDBox>
        <MDBox>
          <MDBox px={6}>
            <RadioGroup name="is_member" onChange={(e) => onChangeOption(e)}>
              <FormControlLabel
                value="isAgent"
                control={<Radio />}
                label="Extensión propia"
              />
              <FormControlLabel
                value="notAgent"
                control={<Radio />}
                label="Call Center"
              />
{/*               <FormControlLabel
                value="admin"
                control={<Radio />}
                label="Entrar en modo Administrador"
              /> */}
            </RadioGroup>
          </MDBox>
          <Card>
            <MDBox px={6} >
{/*               {form.optionLogin === "admin" && (
                <Formik
                  initialValues={{
                    email: "",
                    password: "",
                    rememberMe: false,
                    optionLogin: "admin",
                  }}
                  onSubmit={submit}
                  validate={validation}
                >
                  {(props) => <BasicForm {...props} />}
                </Formik>
              )} */}
              {form.optionLogin === "isAgent" && (
                <Formik
                  initialValues={{
                    email: "",
                    extension: "",
                    rememberMe: false,
                    optionLogin: "isAgent",
                  }}
                  onSubmit={submit}
                  validate={validation}
                >
                  {(props) => <BasicForm {...props} />}
                </Formik>
              )}
              {form.optionLogin === "notAgent" && (
                <Formik
                  initialValues={{
                    token: "",
                    optionLogin: "notAgent",
                  }}
                  onSubmit={submit}
                  validate={validation}
                >
                  {(props) => <BasicForm {...props} />}
                </Formik>
              )}
            </MDBox>
          </Card>
        </MDBox>
      </Card>
    </BasicLayout>
  );
}

export default LoginPage;
