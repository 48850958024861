/* eslint-disable react/prop-types */
// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDAvatar from "components/MDAvatar";
import MDBadge from "components/MDBadge";
import orderImage from "assets/images/photo-user-blank.jpg";
import { useLocation } from "react-router-dom";
import { useContext, useEffect,useState } from 'react';
import { DetailCallContext } from "hooks/DetailCallContext";
import Swal from "sweetalert2";

function HeaderPhoto({ blacklisted }) {
  const { search } = useLocation();
  const number = new URLSearchParams(search).get("number");
  const displayName = new URLSearchParams(search).get("displayName");
  const { getDetailPhone } = useContext(DetailCallContext);

  return (
    <Grid container spacing={0} alignItems="center">
      <Grid item xs={12} md={12}>
        <MDBox display="flex" alignItems="center">
          <MDBox mr={2}>
            <MDAvatar size="sm" src={orderImage} alt="Gold Glasses" />
          </MDBox>
          <MDBox lineHeight={1}>
            <MDTypography variant="h6" fontWeight="medium">
              Contact 3cx - {number}
            </MDTypography>
            <MDBox mb={1}>
              <MDTypography variant="h6" color="text">
                {displayName && `Linea: ${displayName}`}
              </MDTypography>
            </MDBox>
            <MDBadge
              variant="gradient"
              size="sm"
              color={(() => {
                if(getDetailPhone?.contact?.blacklisted) return "error";
                if(getDetailPhone?.contact?.updatedAt != getDetailPhone?.contact?.createdAt){
                  return "success" 
                }else{
                  return "warning" 
                }
              })()}
              container
              badgeContent={(() => {
                if(getDetailPhone?.contact?.blacklisted) return "Usuario reportado";
                if(getDetailPhone?.contact?.updatedAt != getDetailPhone?.contact?.createdAt){
                  return "Usuario registrado" 
                }else{
                  return "Usuario NO registrado" 
                }
              })()}
            />
          </MDBox>
        </MDBox>
      </Grid>
    </Grid>
  );
}

export default HeaderPhoto;
