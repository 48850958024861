/* eslint-disable no-underscore-dangle */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
/* eslint-disable no-undef */
/* eslint-disable consistent-return */
/* eslint-disable no-param-reassign */
import { useState, useEffect, useContext, useLayoutEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Autocomplete from "@mui/material/Autocomplete";
// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDEditor from "components/MDEditor";
// import MDInput from "components/MDInput";
// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, Input } from "@mui/material";
import { Field, FieldArray } from "formik";
import DatePicker, { registerLocale } from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import MDButton from "components/MDButton";
import es from "date-fns/locale/es";
import Icon from "@mui/material/Icon";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";
import Book from "./bookOration";
import HeaderPhoto from "../HeaderPhoto";
import { fetchApi } from "../../../helpers/fetch";
import ContactNotesTable from 'components/Tables/ContactNotesTable';
import TextareaAutosize from '@mui/material/TextareaAutosize';

registerLocale("es", es);
const MySwal = withReactContent(Swal)
const _ = require("lodash");

function FormAtention({
  handleChange,
  handleSubmit,
  /*   isSubmitting,
  resetForm, */
  values,
  /*   errors, 
  touched,*/
  isValid,
  data,
  handleNotes,
}) {
  const [getOther, setOther] = useState({
    other: false,
 /*    optionScheduleCall: "false",
    optionBrujeria: "false", */
  });
  
  const [getBookOration,setBookOration] = useState();
  const [problems] = useState(["Familiar","Salud","Sentimental","Espiritual","Vicios"]);
 
  const setMinutes = (d, minute) => {
    d.setMinutes(minute);
    return d;
  };
  const setHours = (d, hour) => {
    d.setHours(hour);
    return d;
  };

  const handleChangeOption = (e) => {
    const { name, value } = e.target;
    setOther((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  const sendNoteBL = async (e, type) => {
    const agent = localStorage.getItem("agent");
    const agent_name = localStorage.getItem("name");
    let dataJson = {};
    let response = {};
    if (type == "note") {
      dataJson = {
        contact_id: data.contact._id,
        type_note: "note",
        note: e.value,
        agent,
        agent_name,
      };
      response = await fetchApi("createNoteBL", dataJson, "POST");
    }
    if (type == "blacklist") {
      dataJson = {
        contact_id: data.contact._id,
        type_note: "blacklist",
        note: e.value,
        agent,
        agent_name,
      };
      response = await fetchApi("createNoteBL", dataJson, "POST");
    }
    handleNotes(dataJson);
    return response;
  };

  const handleMemberNameChange = (idx) => (evt) => {
    const newMembers = getBookOration.members.map((member, sidx) => {
      if (idx !== sidx) return member;
      console.log( { ...member, name: evt.target.value });
      return { ...member, name: evt.target.value };
    });
    
    setBookOration({ members: newMembers });
    values.book_oration = newMembers ;
    
  };
  
  const handleRemoveMember = (idx) => () => {
    setBookOration({
        members: getBookOration.members.filter((s, sidx) => idx !== sidx),
    });
  };
  const handleAddMember = () => {
    let validateItem = _.map(getBookOration.members , (item) => _.includes(item, "" ))
    
    if(_.includes(validateItem,true)) return;
    if(!getBookOration.members.length || _.includes(validateItem,false))
    
    
    
    setBookOration({
        members:[...getBookOration.members,{ name: "" }],
    });
    values.book_oration = [...values.book_oration,{ name: "" }]
    // console.log(getBookOration.concat([{ name: "" }]));
    // this.props.parentCallback(getBookOration);
    

    // this.inputRef.focus();
  };
  const [startDate, setStartDate] = useState(setHours(setMinutes(new Date(), 30), 16));
  const [edit, setEdit] = useState(true)
  
  const editForm = () => {
  setEdit(false);
  }
  
  return (
    <form onSubmit={handleSubmit}>
      <MDTypography variant="h6" fontWeight="medium">
        Formulario CallCenters
      </MDTypography>
      <MDBox>
        <Grid container spacing={1}>
          <Grid item xs={4} sm={4}>
            {!edit && (
                <MDButton
                size="small"
                spacing={3}
                variant="gradient"
                color="success"
                disabled={!isValid}
                type="submit"
              >
                Guardar&nbsp;
                <Icon>save</Icon>
              </MDButton>
            )}

            {edit && (
                <MDButton
                size="small"
                spacing={3}
                variant="gradient"
                color="info"
                type="submit"
                onClick={editForm}
              >
                Editar&nbsp;
                <Icon>edit</Icon>
              </MDButton>  
            )}
          
          </Grid>
          <Grid item xs={4} sm={4}>
            <MDButton
              size="small"
              variant="gradient"
              color="secondary"
              onClick={() => {
                
                MySwal.fire({
                  width: 800,
                  html:<ContactNotesTable data={data.notesLog.note} />,
                  input: "textarea",
                  inputLabel: "Agregar Nota",
                  inputPlaceholder: "Escriba la nota que desea agregar...",
                  inputAttributes: {
                    "aria-label": "Escriba la nota que desea agregar...",
                  },
                  showCancelButton: true,
                }).then((res) => {
                  if (res.isConfirmed) {
                    sendNoteBL(res, "note");
                    Swal.fire("Nota Agregada Correctamente", "", "success");
                  }
                });
              }}
            >
              Agregar Nota
              <Icon>note_add</Icon>
            </MDButton>
          </Grid>
          <Grid item xs={4} sm={4}>
            <MDButton
              size="small"
              spacing={3}
              variant="gradient"
              color="error"
              onClick={() => {
                MySwal.fire({
                  width: 800,
                  title: "Reportar Número",
                  html:<ContactNotesTable data={data.notesLog.blacklist} />,
                  input: "select",
                  inputOptions: {
                    "LLamada de Broma/Grocerias": "LLamada de Broma/Grocerias",
                    "No desea dar informacion": "No desea dar informacion",
                    "Bancos/Cobranza/Ventas": "Bancos/Cobranza/Ventas",
                  },
                  inputPlaceholder: "Seleccione una opción",
                  showCancelButton: true,
                  inputValidator: (value) =>
                    new Promise((resolve) => {
                      if (value) {
                        resolve();
                      } else {
                        resolve("Favor de seleccionar una opción :)");
                      }
                    }),
                }).then((res) => {
                  if (res.isConfirmed) {
                    sendNoteBL(res, "blacklist");
                    Swal.fire(
                      "Número Reportado Correctamente",
                      "En 24 hrs se realizara el bloqueo..",
                      "success"
                    );
                  }
                });
              }}
            >
              Reportar&nbsp;
              <Icon>phone_disabled</Icon>
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <HeaderPhoto data={data} />
      <Card>
        <MDBox p={3}>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="text"
                  label="Nombre Completo"
                  name="name"
                  value={values.name}
                  onChange={handleChange}
                  disabled = {edit}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormField
                  type="text"
                  label="Correo Electronico?"
                  name="email"
                  value={values.email}
                  onChange={handleChange}
                  disabled = {edit}
                />
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormLabel
                  sx={{
                    fontSize: 14,
                  }}
                  id="radio-buttons-group-label"
                >
                  Ya Participa en la Iglesia ?
                </FormLabel>
                <RadioGroup onChange={handleChange} name="is_member" value={values.is_member}>
                  <FormControlLabel value="si" control={<Radio />} label="si" disabled = {edit} />
                  <FormControlLabel value="no" control={<Radio />} label="no" disabled = {edit} />
                  <FormControlLabel
                    value="esta apartado"
                    control={<Radio />}
                    label="Esta apartado"
                    disabled = {edit}
                  />
                </RadioGroup>
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel
                  sx={{
                    fontSize: 14,
                  }}
                  id="radio-buttons-group-label"
                >
                  Te gustaría recibir llamada Telefonica ?
                </FormLabel>
                <Field>
                  {({ form }) => (
                    <RadioGroup
                      name="optionScheduleCall"
                      value={values.optionScheduleCall}
                      onChange={(e) => {
                        handleChangeOption(e);
                        form.setFieldValue("optionScheduleCall", e.target.value);
                        if (e.target.value === "false") {
                          form.setFieldValue("call_hour", "");
                        }
                      }}
                    >
                      <FormControlLabel value control={<Radio />} label="si" disabled = {edit} />
                      <FormControlLabel value={false} control={<Radio />} label="no" disabled = {edit} />
                    </RadioGroup>
                  )}
                </Field>
                {(getOther.optionScheduleCall === "true" || values.optionScheduleCall === true) && (
                  <>
                    <FormLabel
                      sx={{
                        fontSize: 14,
                      }}
                      id="radio-buttons-group-label"
                    >
                      En que horario ?
                    </FormLabel>
                    <Field>
                      {({ form }) => (
                        <RadioGroup name="call_hour" onChange={handleChange} value={values.call_hour} >
                          <FormControlLabel value="M" control={<Radio />} label="Mañana" disabled = {edit} />
                          <FormControlLabel value="T" control={<Radio />} label="Tarde" disabled = {edit} />
                          <FormControlLabel value="N" control={<Radio />} label="Noche" disabled = {edit} />
                        </RadioGroup>
                      )}
                    </Field>
                  </>
                )}
              </Grid>
            </Grid>
          </MDBox>
          <MDBox mt={1}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={6}>
                <FormLabel
                  sx={{
                    fontSize: 14,
                  }}
                  id="radio-buttons-group-label"
                >
                  Que problemas tiene ?
                </FormLabel>
                
                {problems.map((problem) =>{
                  
                    return (                
                      <FormControlLabel
                        control={
                          <Checkbox
                            disabled = {edit}
                            name="typeProblems"
                            id="typeProblems"
                            value={problem}
                            checked={(()=>{
                              if(values.typeProblems.find( row => row == problem) ){
                                return true;
                              }else{
                                return false;
                              }
                            })()}
                            onChange={handleChange}
                          />
                        }
                        label={problem}
                      />
                    );
                  
                })}

                <FormControlLabel
                  control={
                    <Field>
                      {({ form }) => (
                        <Checkbox
                          disabled = {edit}
                          name="typeProblems"
                          value="otro"
                          onChange={(e) => {
                            // handleChange();
                            const { checked } = e.target;
                            if (!checked) {
                              _.map(getOther);
                              form.setFieldValue("optionOtherProblem", "false");
                              form.setFieldValue("otherProblem", "");
                              return setOther((prevState) => ({
                                ...prevState,
                                other: false,
                              }));
                            }
                            form.setFieldValue("optionOtherProblem", "true");
                            return setOther((prevState) => ({
                              ...prevState,
                              other: true,
                            }));
                          }}
                        />
                      )}
                    </Field>
                  }
                  label="Otro Problema"
                />

                {getOther.other && (
                  <Field>
                    {({ form }) => (
                      <FormField
                        type="text"
                        label="Otro"
                        name="otherProblem"
                        placeholder="Descripción Breve del problema"
                        onBlur={(e) => {
                          const { value } = e.target;
                          /* form.setFieldValue("typeProblems", [
                              ...values.typeProblems,
                              { otro },
                            ]); */
                          form.setFieldValue("otherProblem", value);
                        }}
                      />
                    )}
                  </Field>
                )}
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormLabel
                  sx={{
                    fontSize: 14,
                  }}
                  id="radio-buttons-group-brujeria"
                >
                  ¿Cree que es víctima de brujería?
                </FormLabel>
                <Field>
                  {({ form }) => (
                    <RadioGroup
                      name="optionBrujeria"
                      value={values.optionBrujeria}
                      onChange={(e) => {
                        handleChangeOption(e);
                        if (e.target.value === "false") {
                          form.setFieldValue("elementos_brujeria", "");
                          form.setFieldValue("sintomas_brujeria", "");
                        }
                        form.setFieldValue("optionBrujeria", e.target.value);
                      }}
                    >
                      <FormControlLabel value="true" control={<Radio />} label="si" disabled = {edit} />
                      <FormControlLabel value="false" control={<Radio />} label="no" disabled = {edit} />
                    </RadioGroup>
                  )}
                </Field>
                {(getOther.optionBrujeria === "true" || values.optionBrujeria === true ) && (
                  <>
                    <Grid>
                      <FormLabel
                        sx={{
                          fontSize: 14,
                        }}
                        id="radio-buttons-group-brujeria"
                      >
                        ¿Encontró algún elemento?
                      </FormLabel>
                      <br />
                      <Field>
                        {({ form }) => (
                          <TextareaAutosize
                            disabled = {edit}
                            minRows={3}
                            style={{ width: "100%" }}
                            value={values.elementos_brujeria}
                            name="elementos_brujeria"
                            placeholder="favor de incluir detalles"
                            onChange={handleChange}
                        />
                        )}
                      </Field>
                    </Grid>
                    <Grid>
                      <FormLabel
                        sx={{
                          fontSize: 14,
                        }}
                        id="radio-buttons-group-brujeria"
                      >
                        ¿Cuáles cambios repentinos o síntomas ha presentado?
                      </FormLabel>
                      <br />
                      <Field>
                        {({ form }) => (
                          <TextareaAutosize
                            disabled = {edit}
                            minRows={3}
                            style={{ width: "100%" }}
                            value={values.sintomas_brujeria}
                            name="sintomas_brujeria"
                            placeholder="favor de incluir detalles"
                            onChange={handleChange}
                        />                          
                        )}
                      </Field>
                      
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </MDBox>
          
            
          <MDBox>
              <Grid container spacing={3}>
                  <Grid item xs={12} sm={12}>
                    <FieldArray name="book_oration">
                        {({ insert, remove, push }) => (
                          <div>
                            {values.book_oration.length > 0 && values.book_oration.map((member, idx) => {

                              return (
                                    <div key={idx}>
                                        <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                                            <FormField
                                            label="LIbro de oración"
                                            type="text"
                                            placeholder={`#${idx + 1} Escriba a un miembro`}
                                            value={member.name}
                                            name={`book_oration.${idx}.name`}
                                            onChange={handleChange}
                                            disabled = {edit}
                                            />                            
                                            <MDBox mr={1}>
                                                <MDButton variant="text" onClick={() => remove(idx)} color="error">
                                                <Icon>delete</Icon>&nbsp;Eliminar
                                                </MDButton>
                                            </MDBox>
                                        </MDBox>
                                    </div>
                                  )
                              }                              
                            )}
                          <MDButton variant="gradient"  onClick={() => push({ name: ""})} color="info">
                              <Icon>menu_book</Icon>&nbsp;
                              Agregar al libro
                          </MDButton>                              
                          </div>
                        
                        )}
                    </FieldArray>
                  </Grid>
              </Grid>
          </MDBox>
        
          <MDBox>
            <Grid p={3}>
              <MDButton
                variant="gradient"
                color="success"
                disabled={!isValid}
                type="submit"
                fullWidth
              >
                Guardar Formulario
              </MDButton>
            </Grid>
          </MDBox>
        </MDBox>
      </Card>
    </form>
  );
}

export default FormAtention;
