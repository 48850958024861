/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useCallback, useState } from "react";
import { fetchConToken, fetchSinToken, fetchApi } from "../helpers/fetch";

export const DetailCallContext = createContext();

const initialState = {
  contact:{}
};

export function DetailCallProvider({ children }) {
  const [getDetailPhone, setDetailPhone] = useState(initialState);

  const detailCall = async(number=5568024467) => {
      
    const data = await fetchApi(`searchNumber?number=${number}`);
    
    setDetailPhone(data);
    return data;
  };

  return (
    <DetailCallContext.Provider
      value={{
        detailCall,
        getDetailPhone
      }}
    >
      {children}
    </DetailCallContext.Provider>
  );
}
