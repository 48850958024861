/* eslint-disable prettier/prettier */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable camelcase */
import { React, useState, useEffect, useContext } from 'react';
import Grid from "@mui/material/Grid";
import Select from "react-select" ;
import MDTypography from "components/MDTypography";
import AsyncSelect from 'react-select/async';
// Material Dashboard 2 PRO React examples
// import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import GoogleMap from 'google-map-react';

import SalesTable from "examples/Tables/SalesTable";
import { useGetSepomexEstado, useGetEstadosIglesia } from '../../hooks/useGetSepomex';
import { fetchApi } from "../../helpers/fetch";
// import pinLogo from "assets/images/logo-ct-dark.png";
import MDBox from "components/MDBox";
import Swal from "sweetalert2";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { DetailCallContext } from "hooks/DetailCallContext";
import FormField from "layouts/applications/wizard/components/FormField";
const _ = require("lodash");

function ChurchAssigned({ data,handleChangeDireccion }) {
  const { getDetailPhone } = useContext(DetailCallContext);
  const dataSepomexEstado = useGetSepomexEstado('sepomex/estados');
  const [municipios, setMunicipios] = useState({
    data:[],
    selected:[]
  });
  const [colonias, setColonias] = useState({
    data:[],
    selected:null
  });
  const [google, setGoogle] = useState({
    center: {
      lat: 19.4022426605225,
      lng: -99.1889114379883
    },
    zoom: 6
  })
  const [SelectEstado, setSelectEstado] = useState({
    defaultValue:{},
    estado:null,
    data:[]
  })
  const [book, setBook] = useState();
  const [address, setAddress] = useState();
  useEffect(async () => {
    // if( data.estate && Object.keys(data.estate).length > 0 ) handleChangeEstate(data.estate);
    if(getDetailPhone.contact.estado){
      setSelectEstado({
        selected:{label: getDetailPhone.contact.estado}
      });
    }else{
      if( data.estate && Object.keys(data.estate).length > 0 ) handleChangeEstate(data.estate);
    }
    
    if(getDetailPhone.contact.municipio){
      setMunicipios({
        selected:{label: getDetailPhone.contact.municipio}
      });      
    }

    if(getDetailPhone.contact.colonia){
      setColonias({
        selected:{label:getDetailPhone.contact.colonia}
      });
    }    

    if(getDetailPhone.contact.book){
      setBook(true);
    }    
    if(getDetailPhone.contact.address){
      setAddress(true);
    } 

  }, [getDetailPhone.contact]);

  // const dataSepomexMunicipio = useGetSepomexMunicipio('sepomex/municipios',1);
  const handleChangeEstate = async (selectedOption) => {
    // this.setState({ selectedOption });
    // selectedOption can be null when the `x` (close) button is clicked
    if (selectedOption) {
      const dataMunicipios = await fetchApi(`sepomex/municipios/${selectedOption.value}`);
      const data = await fetchApi(`iglesia/estados/${selectedOption.label}`);
      setSelectEstado({
        estado:selectedOption.label,
        data,
        selected:selectedOption
      });
      const result = dataMunicipios.map(({ id, nombre }) => ({ value: id, label: nombre }))
      setMunicipios({
        data:result,
        selected:''
      });
      setColonias({
        data:[],
        selected:null
      });
      handleChangeDireccion({
        municipio:municipios.selected,
        estado:selectedOption,
        colonia:colonias.selected,
        address:address
      });
    }
  }
  
  const handleChangeMunicipio = async (selectedOption) => {
    // this.setState({ selectedOption });
    // selectedOption can be null when the `x` (close) button is clicked
    setMunicipios( {...municipios, selected:selectedOption});
    if (selectedOption) {
      const dataColonias = await fetchApi(`sepomex/colonias/${selectedOption.value}`);
      const result = dataColonias.map(({ id, nombre, codigo_postal, latitud , longitud }) => ({ codigo_postal,value: id, label: `${nombre} - ${codigo_postal}`,latitud , longitud }))
      setColonias({
        data:result,
        selected:null
      });
    }
    handleChangeDireccion({
      municipio:selectedOption,
      estado:SelectEstado.selected,
      colonia:colonias.selected,
      address:address
    });
  }
  
 
  
  
  const [counter, setCounter] = useState(0);
  const [points, setPoints] = useState([]);
  const handleChangeColonia = async(selectedOption) => {
    setColonias( {...colonias, selected:selectedOption});
    const dataProximidad = await fetchApi(`iglesias/proximidad/${selectedOption.codigo_postal}?latitud=${selectedOption.latitud}&longitud=${selectedOption.longitud}`);
    let dataPoints = dataProximidad.points;
    setPoints(dataPoints);
    let direcciones = dataPoints[0].map( ({distance,nome,direccion,estado, referencia}) => ({distancia : distance,nombreIglesia:nome,direccion,estado, referencia }) );
    setSelectEstado( {...SelectEstado, data:direcciones,selected:dataProximidad.estado} );
    setMunicipios( {...municipios, selected:dataProximidad.municipio} );
    handleChangeDireccion({
      municipio:dataProximidad.municipio,
      estado:dataProximidad.estado,
      colonia:selectedOption,
      address:address
    });
    if(dataProximidad.points.length){
      setCounter(0);
      Swal.fire({
        title: `La iglesia más cerca es: <p>${ dataPoints[0][0]["nome"]}`,
        icon: 'success',
        html: dataPoints[0][0]["direccion"],
        showConfirmButton: true,
        showCancelButton: false,
      })
    }
  }
  const [inputField, setInputField] = useState();
  const handleInputColonia = async(inputField,callback) => {
    if (inputField) {
      const dataColonias = await fetchApi(`sepomex/colonias/cp/${inputField}`);
      const result = dataColonias.map(({ id, nombre, codigo_postal, latitud , longitud}) => ({ value: id, label: `${nombre} - ${codigo_postal}`,latitud , longitud, codigo_postal }))
      if(result.length){
        setColonias({
          data:result,
          selected:null
        });
        return result
      }else{
        var list = colonias.data;
        const filtered = _.filter(list, function(o) {
          return o.label.toLowerCase().indexOf(inputField) > -1;
        });
        return filtered
      }
    }

  }
  
  
  const addChurch = ()=>{
    
    
    let direcciones = points[(counter + 1)].map( ({distance,nome,direccion,estado, referencia}) => ({distancia : distance,nombreIglesia:nome,direccion,estado, referencia }) );  
    direcciones = _.union(SelectEstado.data,direcciones);
    setCounter(counter + 1);
    setSelectEstado( {...SelectEstado, data:direcciones} );
  }
  
  const handleInputChange = async(newValue) => {
    const inputValue = newValue.replace(/\W/g, '');
    // setInputField({ inputValue });
    return inputValue;
  };
  
  const sendBook = async() =>{
    setBook(true);
    Swal.fire('Agente buenas noches, hay que indicar a la persona que el registro para el libro solo se esta haciendo por mensaje en el whatsapp 5555743266 y allí van a recibir indicaciones de como proceder.', '', 'info')
    handleChangeDireccion({
      municipio:municipios.selected,
      estado:SelectEstado.selected,
      colonia:colonias.selected,
      address:address,
      book:true
      
    });
  }
  
  const onChangeSendBook = async(e) =>{
    setAddress(e.target.value)
    handleChangeDireccion({
      municipio:municipios.selected,
      estado:SelectEstado.selected,
      colonia:colonias.selected,
      address:address,
      book:true
    });
    
  }

  
  const styles = {
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? 'white' : 'grey',
      paddingTop: 0,
      paddingBottom: 0,
    }),
    input: (provided, state) => ({
      ...provided,
      padding: -10,
      paddingTop: 0,
      paddingBottom: 0,
    })
  } 
  return (
    <>
       <MDButton
        size="small"
        spacing={3}
        variant="gradient"
        color="info"
        type="submit"
        onClick={sendBook}
      >
        ¿Quiere libro ?&nbsp;
        <Icon>book</Icon>
      </MDButton>  
      <MDTypography variant="h6" color="text">
        Buscar Iglesia más cercana al domicilio del contacto.
      </MDTypography>
      <Grid container spacing={1} xs={12} lg={12}>
        <Grid item xs={12} sm={4} >
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select styles={styles} options={dataSepomexEstado} value={SelectEstado.selected} onChange={handleChangeEstate} name="estado" placeholder="Estado" />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
            <Select styles={styles} options={municipios.data} value={municipios.selected} name="municipio" onChange={handleChangeMunicipio} placeholder="Municipio" />
          </FormControl>
        </Grid>
        <Grid item xs={12} sm={4}>
          <FormControl fullWidth>
            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> 
            <Select options={colonias.data} value={colonias.selected} onChange={handleChangeColonia} name="colonia" placeholder="Seleccione una colonia" /> */}
            <AsyncSelect
              styles={styles}
              defaultOptions={colonias.data}
              loadOptions={handleInputColonia}
              value={colonias.selected}
              onInputChange={handleInputChange}
              onChange={handleChangeColonia}
              onClick={handleChangeColonia}
              name="colonia"
              placeholder="Escribir CP"
              // (municipios.data)  ? "Escribir CP" : "Seleccionar Colonia"
            />          
          </FormControl>
        </Grid>
      </Grid> 
      {( book && (     
      <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              type="text"
              label="Calle y número, solicite alguna referencia "
              name="address"
              onKeyUp={onChangeSendBook}
            />
          </Grid>
        </Grid>
      </MDBox> )
        )}
      <br />
      <MDTypography variant="h6" fontWeight="medium">
        Iglesias más cercanas
      </MDTypography>
      <Grid item xs={12} md={12} lg={12}>
{/*         <div style={{ height: '70vh', width: '100%' }}>
          <GoogleMap
            bootstrapURLKeys={{ key: 'AIzaSyCfnDJaamabdJmhP4o0BWrASS2dkZJ86Oc' }}
            defaultCenter={google.center}
            defaultZoom={google.zoom}
          >
            {(SelectEstado!== null && data.groupEstates[SelectEstado]) && data.groupEstates[SelectEstado].map(({latitude,longitude,nome,id}) =>{
                setGoogle({
                  center: {
                    lat:latitude,
                    lng:longitude
                  },
                  zoom: 6
                });
            })}
            {(SelectEstado!== null && data.groupEstates[SelectEstado]) && data.groupEstates[SelectEstado].map(({latitude,longitude,nome,id}) =>{
            return (
                <AnyReactComponent
                lat={latitude}
                lng={longitude}
                text={nome}
                key={id}
              />
              )})}

          </GoogleMap>
        </div> */}
        {(SelectEstado.estado!== null) && (<SalesTable rows={SelectEstado.data} />)}
        {(SelectEstado.estado == null) && (<SalesTable rows={data.addresses_iurd} />)}
        {(points.length>0) && (<MDBox p={2} display="flex" justifyContent="center">
          <MDBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            color="white"
            height="4rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
              <MDButton
                variant="gradient"
                color="warning"
                onClick={addChurch}
              >
                Ver más...&nbsp;
              <Icon>public</Icon>
              </MDButton>
          </MDBox>
        </MDBox>)}       
        
      </Grid>
    </>
  );
}
// const AnyReactComponent = ({ text }) => <div><img src="http://maps.google.com/mapfiles/kml/paddle/A.png" /></div>;
export default ChurchAssigned;
