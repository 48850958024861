/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Countries flags
import US from "assets/images/icons/flags/US.png";
import DE from "assets/images/icons/flags/DE.png";
import GB from "assets/images/icons/flags/GB.png";
import BR from "assets/images/icons/flags/BR.png";
import MX from "assets/images/icons/flags/MX.png";

const salesTableData = [
  {
    "ciudad-pais": [MX, "CDMX"],
    llamadas: 2500,
    porcetaje: "35%",
  },
  {
    "ciudad-pais": [MX, "VERACRUZ"],
    llamadas: "3900",
    porcetaje: "35%",
  },
  {
    "ciudad-pais": [MX, "JALISCO"],
    llamadas: "1400",
    porcetaje: "5%",
  },
  { "ciudad-pais": [MX, "OAXACA"], llamadas: 562, porcetaje: "20%" },
  { "ciudad-pais": [US, "Estados unidos"], llamadas: 400, porcetaje: "5%" },
];

export default salesTableData;
