import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchApi } from "helpers/fetch";
// Data
import dataTableData2 from "examples/Tables/AgentsTable/SalesTableCell";

function OrderList(refresh) {
  

  const [menu, setMenu] = useState(null);
  const [DataTabl, setDataTabl] = useState({
    columns: [
      { Header: "Extension"},
      { Header: "Usuario"},
      { Header: "Grupo" },
      { Header: "Call Center" },
      { Header: "Contraseña Asignada" },
      { Header: "Estatus" },
      { Header: "Acciones"},
    ],
  
    rows: [],
  });
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(async () => {
    const ext = localStorage.getItem("agent");
    const dataLog = await fetchApi(`/call-center/list`);
    console.log(dataLog);
    const dt =  await dataTableData2(dataLog);
    setDataTabl(dt);
  }, [refresh]);
  
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Tipo de llamada: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Tipo de llamada: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Tipo de llamada: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (

      <MDBox my={1}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" >
          <MDBox display="flex">

          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={DataTabl} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>

  );
}

export default OrderList;
