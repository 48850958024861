/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Tooltip from "@mui/material/Tooltip";
import Icon from "@mui/material/Icon";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDBadgeDot from "components/MDBadgeDot";
import MDButton from "components/MDButton";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DefaultStatisticsCard from "examples/Cards/StatisticsCards/DefaultStatisticsCard";
import DefaultLineChart from "examples/Charts/LineCharts/DefaultLineChart";
import HorizontalBarChart from "examples/Charts/BarCharts/HorizontalBarChart";
import DataTable from "examples/Tables/DataTable";

// Sales dashboard components
import ChannelsChart from "layouts/dashboards/sales/components/ChannelsChart";

// Data
import defaultLineChartData from "layouts/dashboards/sales/data/defaultLineChartData";
import horizontalBarChartData from "layouts/dashboards/sales/data/horizontalBarChartData";
import salesTableData from "layouts/dashboards/sales/data/salesTableData";
import dataTableData from "layouts/dashboards/sales/data/dataTableData";
import FormUserCallCenter from './formUserCallCenter';
import TableAgentsLog from "./tableLogAgentCallCenter";
import SalesTable from "examples/Tables/SalesTable";
import AgentsTable from "examples/Tables/AgentsTable";
import { useGetCallCentersLogs } from '../../hooks/useGetSepomex';

function adminCallCenterForm() {
  const [Agent, setAgent] = useState({});
  const callCenterList  = useGetCallCentersLogs(Agent);
  // const [ list, dispatch ] = useReducer(callCenterList, []);
   const handleAddAgent = (e) =>{
    setAgent(e);
  }
  return (
    <DashboardLayout>
      <MDBox py={3}>         
        <MDBox mb={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
                
                <MDBox my={1}>
                    <Grid container spacing={1} justifyContent="center">
                        <Grid item xs={12} lg={12}>
                            <Card>
                                <MDBox pt={1} pb={3} px={2}>
                                    <MDBox mt={3}>
                                        <FormUserCallCenter handleAddAgent={handleAddAgent} />
                                    </MDBox>
                                </MDBox>
                            </Card>
                        </Grid>
                    </Grid>
                </MDBox>                                                 
            </Grid>              
            <Grid item xs={12} lg={8}>
            {/* <AgentsTable title="Agentes registrados" rows={callCenterList} /> */}
            <AgentsTable refresh={Agent}/>
            </Grid>

          </Grid>
        </MDBox>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default adminCallCenterForm;
