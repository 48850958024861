/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useCallback, useState } from "react";
import { fetchConToken, fetchSinToken } from "../helpers/fetch";

export const AuthContext = createContext();

const initialState = {
  uid: null,
  isAdmin: false,
  checking: true,
  logged: false,
  name: null,
  email: null,
};

export function AuthProvider({ children }) {
  const [auth, setAuth] = useState(initialState);

  const login = async (data) => {
    // const { email, password } = data;
    const resp = await fetchSinToken("login", data, "POST");

    if (resp.ok) {
      localStorage.setItem("token", resp.token);
      const { usuario } = resp;

      setAuth({
        uid: usuario.uid,
        isAdmin: usuario.isAdmin,
        checking: false,
        logged: true,
        name: usuario.nombre,
        email: usuario.email,
      });
    }

    return resp;
  };

  const loginAgent = async (data) => {
    // const { email, password } = data;
    const resp = await fetchSinToken("login/loginAgent", data, "POST");
    return resp;
    
  };
  
  const callCenter = async (data) => {
    // const { email, password } = data;
    const resp = await fetchSinToken("login/loginAgent", data, "POST");

    if (resp.ok) {
      localStorage.setItem("token", resp.token);
      const { usuario } = resp;

      setAuth({
        uid: usuario.uid,
        checking: false,
        logged: true,
        name: usuario.nombre,
        email: usuario.email,
      });
    }

    return resp;
  };
  
  const register = async (nombre, email, agent, password) => {
    const resp = await fetchSinToken("login/new", { nombre, email, agent, password }, "POST");

    if (resp.ok) {
      localStorage.setItem("token", resp.token);
      const { usuario } = resp;

      setAuth({
        uid: usuario.uid,
        isAdmin: usuario.isAdmin,
        checking: false,
        logged: true,
        name: usuario.nombre,
        email: usuario.email,
      });

      return true;
    }

    return resp.msg;
  };

  const verificaToken = useCallback(async () => {
    const token = localStorage.getItem("token");
    // Si token no existe
    if (!token) {
      setAuth({
        uid: null,
        checking: false,
        logged: false,
        name: null,
        email: null,
      });

      return false;
    }

    const resp = await fetchConToken("login/renew");
    if (resp.ok) {
      localStorage.setItem("token", resp.token);
      const { usuario } = resp;

      setAuth({
        uid: usuario.uid,
        isAdmin: usuario.isAdmin,
        checking: false,
        logged: true,
        name: usuario.nombre,
        email: usuario.email,
      });

      return true;
    }
    setAuth({
      uid: null,
      checking: false,
      logged: false,
      name: null,
      email: null,
    });

    return false;
  }, []);

  const logout = () => {
    localStorage.removeItem("token");
    setAuth({
      checking: false,
      logged: false,
    });
  };

  const updatePasword = async (email, password) => {
    const resp = await fetchSinToken("login/resetPassword", {  email, password }, "POST");

    if (resp.ok) {
      localStorage.setItem("token", resp.token);
      const { usuario } = resp;

      setAuth({
        uid: usuario.uid,
        isAdmin: usuario.isAdmin,
        checking: false,
        logged: true,
        name: usuario.nombre,
        email: usuario.email,
      });
      return {
        uid: usuario.uid,
        isAdmin: usuario.isAdmin,
        checking: false,
        logged: true,
        name: usuario.nombre,
        email: usuario.email,
      };
    }
    return false;
  };
    
  return (
    <AuthContext.Provider
      value={{
        auth,
        login,
        register,
        verificaToken,
        logout,
        loginAgent,
        callCenter,
        updatePasword,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
