/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// Wizard page components
import About from "layouts/applications/wizard/components/About";
import Account from "layouts/applications/wizard/components/Account";
import Address from "layouts/applications/wizard/components/Address";
import { Checkbox, FormControlLabel, FormLabel, Radio, RadioGroup, Input } from "@mui/material";
import MDInput from "components/MDInput";
import { NavLink, useNavigate } from 'react-router-dom';
import { fetchApi } from "helpers/fetch";
import Swal from 'sweetalert2';

function Wizard() {
  const [val, setVal] = useState('');
  const navigate = useNavigate(); 
  const onChange = (e)=>{
    const re = /^[0-9]+$/;
    if (e.target.value === '' || re.test(e.target.value)) {
        setVal( e.target.value );
    }          
  }
    
  const validatePhone = async(e)=>{
    const phone = await fetchApi(`searchNumber?number=${val}`);
    if(!phone.contact?._id){
        Swal.fire("Este número no existe en 3cx", "", "error");
        
        Swal.fire({
            title: '¡Este número no existe!',
            html: `
            ¿Desea dar de alta un número? .
            <br/>
            <input autocomplete="off" class="swal2-input" id="firstname" type="text" placeholder="Nombre " /><br />
            <input autocomplete="off" class="swal2-input" id="mobilephone" value=${val} type="text" placeholder="Teléfono a 10 digitos" />
            `,
            focusConfirm: false,
            inputAttributes: {
              autocapitalize: 'off',
              autocomplete: 'off'
            },
            showCancelButton: true,
            confirmButtonText: 'Registrar telefono',
            showLoaderOnConfirm: true,
            preConfirm: () => {
                
              const firstname = Swal.getPopup().querySelector('#firstname').value;
              const mobilephone = Swal.getPopup().querySelector('#mobilephone').value;
              if(mobilephone){
                return fetchApi( "create",{ firstname , mobilephone} , "POST")
                .then(response => {
                  if (!response.contact._id) {
                    throw new Error(`Hubo un error al crear el telefono, favor de reportarlo a soporte@iurdsys.net`)
                  }
                  
                  setVal(mobilephone);
                  return response;
                })
                .catch(error => {
                  Swal.showValidationMessage(error)
                })
              }else{
                Swal.showValidationMessage(`Favor de escribir un teléfono válido`);
              }
  
            },
            allowOutsideClick: () => !Swal.isLoading()
          }).then((result) => {
            if (result.isConfirmed) {
                navigate(`/calls?number=${val}`);
            } 
          }) 
                    
          
    }else{
        console.log("si existo")
        navigate(`/calls?number=${val}`);
    }
  }
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox  px={1} width="100%" height="100vh" mx="auto">
        <Grid container spacing={1} justifyContent="center" alignItems="center" height="60%" >
            <Grid container spacing={1} justifyContent="center" alignItems="center" height="100%" style={{width: "600px"}} >
                <Grid justifyContent="center" item  xs={8} lg={8} >                    
                    <Card>
                        <MDBox
                            variant="gradient"
                            bgColor="info"
                            borderRadius="lg"
                            coloredShadow="info"
                            mx={2}
                            mt={-3}
                            p={2}
                            mb={1}
                            textAlign="center"
                            >
                            <MDTypography variant="h6" fontWeight="medium" color="white" mt={1}>
                            Escribir Número Telefónico
                            </MDTypography>
                        </MDBox>
                        <MDBox textAlign="center">
                            <MDBox p={1}>
                                <MDInput
                                    type="text"
                                    label="Teléfono"
                                    id="phone_number"
                                    name="phone_number"
                                    fullWidth
                                    pattern="[0-9]*"
                                    value={val}
                                    onChange = {onChange}
                                />
                            </MDBox>
                            <Grid p={3}>
                                {/* <NavLink to={`/calls?number=${val}`} >   */}  
                                    <MDButton
                                        variant="gradient"
                                        color="success"
                                        type="submit"
                                        fullWidth
                                        onClick={validatePhone}
                                    >
                                        Buscar número
                                    </MDButton>
                                {/* </NavLink> */}
                            </Grid>                            
                        </MDBox>
                    </Card>
                </Grid>
            </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default Wizard;
