/* eslint-disable */
import React from "react";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import FormField from "layouts/applications/wizard/components/FormField";
import MDBox from "components/MDBox";
import { Grid } from "@mui/material";
// import { useRef } from 'react';
import { useEffect } from 'react';

export default class IncorporationForm extends React.Component {
  constructor() {
    super();
    this.state = {
      members: [{ name: "" }],
    };

    //this.inputRef = React.createRef();
    //this.onRefMount = this.onRefMount.bind(this);


  }
  
  onRefMount() {
    console.log(this.props);  
/*     React.useEffect(() => {
      console.log(this.inputRef)
      this.inputRef = '0';
    }, []); */
    
     
  }

  handleMemberNameChange = (idx) => (evt) => {
    const newMembers = this.state.members.map((member, sidx) => {
      if (idx !== sidx) return member;
      console.log( { ...member, name: evt.target.value });
      return { ...member, name: evt.target.value };
    });

    this.setState({ members: newMembers });
    
/*     const bookRefs = this.bookRef.map((member, sidx) => {
      if (idx !== sidx) return member;
      console.log( { ...member, current: evt });
      return { ...member, current: evt };
    });
    console.log(bookRefs); */
    
  };

  handleAddMember = () => {
    let validateItem = _.map(this.state.members , (item) => _.includes(item, "" ))

    if(_.includes(validateItem,true)) return;
    if(!this.state.members.length || _.includes(validateItem,false))
    this.setState({
        members: this.state.members.concat([{ name: "" }]),
    });
    
    this.props.parentCallback(this.state.members);
    

    // this.inputRef.focus();
  };

  handleRemoveMember = (idx) => () => {
    this.setState({
        members: this.state.members.filter((s, sidx) => idx !== sidx),
    });
  };
  
  onClick(e) {
    console.log("***************");
    console.log(e)
    //console.log(this.inputRef);
    console.log("***************");
    // this.inputRef.focus();
  }
  
  handleChange(i,e){
    console.log(` aqui llegue => ${i}`);
    // const { name, value } = e.target;
    // this.inputRef[i+1].focus(); 
  }
  
  render() {
    
    return (
        <MDBox>
            <Grid container spacing={3}>
                <Grid item xs={12} sm={12}>
                    {this.state.members.map((member, idx) => (
                    <div key={idx}>
                        <MDBox display="flex" alignItems="center" mt={{ xs: 2, sm: 0 }} ml={{ xs: -1.5, sm: 0 }}>
                            <FormField
                            label="LIbro de oración"
                            type="text"
                            placeholder={`#${idx + 1} Escriba a un miembro`}
                            value={member.name}
                            onChange={this.handleMemberNameChange(idx)}
                            onKeyDown={(e)=>{
                                console.log(idx);
                                //console.log(this.inputRef);
                                console.log("aca estoy");
                                if (e.key === 'Enter') {
                                    console.log("aca llegue");
                                    //this.onClick(e)
                                    this.inputElement.click();
                                    console.log("aca lleguesss");
                                    // console.log(this[`${idx + 1}_ref`]);
                                    // this[`${idx + 1}_ref`].focus();
/*                                     [...this.bookRef,  this.bookRef.current = ref]
                                    console.log(this.bookRef);
                                    
                                    this.bookRef[idx].current.focus() */
                                    // this.inputRef.focus();
                                    console.log("***************");
                                    this.handleChange(idx,e);
                                    console.log("***************");
                                    
                                }
                            }}
                            key={member}
                            />                            
                            <MDBox mr={1}>
                                <MDButton variant="text" onClick={this.handleRemoveMember(idx)} color="error">
                                <Icon>delete</Icon>&nbsp;Eliminar
                                </MDButton>
                            </MDBox>
                        </MDBox>
          
          

                    </div>
                    ))}
                    <MDButton variant="gradient" ref={input => this.inputElement = input} onClick={this.handleAddMember} color="info">
                        <Icon>menu_book</Icon>&nbsp;
                        Agregar al libro
                    </MDButton>
                </Grid>
            </Grid>
        </MDBox>
  
    );
  }
}
