/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

// Images
import team1 from "assets/images/team-1.jpg";
import team2 from "assets/images/team-2.jpg";
import team3 from "assets/images/team-3.jpg";
import team4 from "assets/images/team-4.jpg";
import team5 from "assets/images/team-5.jpg";
import ivana from "assets/images/ivana-squares.jpg";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { NavLink } from 'react-router-dom';

const dataTableData = (data) => ({
  columns: [
    { Header: "Numero", accessor: "number", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Contacto", accessor: "Name", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "Fecha de la llamada",
      accessor: "DateTime",
      Cell: ({ value }) => <DefaultCell value={value} />,
    },
    {
      Header: "Tipo de llamada",
      accessor: "CallType",
      Cell: ({ value }) => {
        let status;
        
        if (value === "Inbound") {
          status = <StatusCell icon="phone-check" color="success" status="Inbound" />;
        } else if (value === "Missed") {
          status = <StatusCell icon="phone_missed" color="dark" status="Missed" />;
        } else if (value === "Outbound") {
          status = <StatusCell icon="phone_forwarded" color="dark" status="Outbound" />;          
        } else if (value === "Notanswered") {
          status = <StatusCell icon="phone_in_talk" color="dark" status="Notanswered" />;     
        }

        return status;
      },
    },
    { Header: "Extension", accessor: "Agent", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Nombre del Agente", accessor: "AgentFirstName", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Cargo", accessor: "AgentLastName", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Acciones" , Cell: ( { row } ) => <NavLink to={`/calls?number=${row.values.number}`} >  <MDButton size="small" spacing={3} color="info">Editar&nbsp;<Icon>edit_icon</Icon></MDButton> </NavLink>},
  ],
  rows: data,
});

export default dataTableData;