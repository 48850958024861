/* eslint-disable no-underscore-dangle */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
/* eslint-disable no-unused-vars */
/* eslint-disable react/jsx-no-undef */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-expressions */
// react-router-dom components
/* import { Link } from "react-router-dom";
import { FormControl } from "@mui/material"; */
// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDButton from "components/MDButton";

// Authentication layout components
import BasicLayout from "layouts/authentication/components/BasicLayout";

// Images
import bgImage from "assets/images/background.jpg";
import { useContext, useEffect, useState } from "react";
import { Formik } from "formik";
import Swal from "sweetalert2";
import { fetchApi } from "../../../helpers/fetch";
import { DetailCallContext } from "hooks/DetailCallContext";
// import BasicForm from "./FormAtention";

function RenderForm({ Component, data = [] , handleNotes, getChangeDireccion}) {
  const submit = async (values, actions) => {
      const dataDireccion = {        
        estado:getChangeDireccion?.estado ?? data.contact?.estado,
        municipio:getChangeDireccion?.municipio ?? data.contact?.municipio,
        colonia:getChangeDireccion?.colonia ?? data.contact?.colonia,
        codigo_postal:getChangeDireccion?.codigo_postal ?? data.contact?.codigo_postal ,
        address:getChangeDireccion?.address ?? data.contact?.address,
        book: getChangeDireccion?.book ?? data.contact?.book
      }
      values.dataDireccion = dataDireccion;
      Swal.fire({
        title: '¿Quieres guardar los cambios?',
        showDenyButton: true,
        confirmButtonText: 'Si',
        denyButtonText: `No `,
      }).then(async(result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          await fetchApi(`/call/${data.contact._id}`, values, "POST");
          Swal.fire('Contacto guardado correctamente!', '', 'success').then( (result)=>{
            if (result.isConfirmed){
              window.opener = null;
              window.open("", "_self");
              window.close();
            }
          })

        } else if (result.isDenied) {
          Swal.fire('Los cambios no fueron guardados', '', 'info')
        }
      })
    actions.setSubmitting(false);
  };

  const validation = (values) => {
    const errors = {};
    if (!values.name) {
      errors.name = "name is required!";
    }
    return errors;
  };
  
  return (
    <Formik
      enableReinitialize
      initialValues={{
        email: data.contact?.email ?? "" ,
        name: data.contact?.firstname ?? "",
        is_member: data.contact?.is_member ?? "",
        optionScheduleCall: data.contact?.wants_contacted ?? null,
        typeProblems: data.contact?.typeProblems ?? [],
        optionOtherProblem: data.contact?.is_member ?? null,
        optionBrujeria: data.contact?.victimaBrujeria ?? null,
        elementos_brujeria: data.contact?.elementos_brujeria ?? "",
        sintomas_brujeria: data.contact?.sintomas_brujeria ?? "",
        call_hour:  data.contact?.call_hour ?? "",
        book_oration: data.contact?.book_oration ?? [{"name":""}],
/*         estado:getChangeDireccion?.estado ?? "",
        municipio:getChangeDireccion?.municipio ?? "",
        colonia:getChangeDireccion?.colonia ?? "",
        codigo_postal:getChangeDireccion?.codigo_postal ?? "", */
      }}
      onSubmit={submit}
      validate={validation}
    >
      {(props) => <Component {...props} data={data} handleNotes={handleNotes} />}
    </Formik>
  );
}

export default RenderForm;
