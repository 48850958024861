import { useEffect, useState } from "react";
import { fetchApi } from "helpers/fetch";

export const useGetDetailCall = (number,getCallsLogs) => {
  const [getDetail, setDetail] = useState({
    callsLog: [],
    contact: {},
  });

  useEffect(async () => {
    const data = await fetchApi(`searchNumber?number=${number}`);
    setDetail(data);
  }, [getCallsLogs]);

  return getDetail;
};

export const listAllNotes = (data,handleChange) => {
  const [getLogs, setLogs] = useState({});

  useEffect(async () => {
    const result = await fetchApi(`listAllNotes?id=${data.contact?._id}&number=${data.contact?.mobilephone}`);
    setLogs(result);
  }, [handleChange]);

  return getLogs;
};

export const listAllCalls = () => {
  const [getAllCalls, setAllCalls] = useState({});

  useEffect(async () => {
    const result = await fetchApi("list-calls");
    setAllCalls(result);
  }, []);

  return getAllCalls;
};



