import { useEffect, useState } from "react";
import { fetchApi } from "helpers/fetch";

export const useGetSepomexEstado = (path) => {
  const [getEstado, setEstado] = useState({
  });

  useEffect(async () => {
    const data = await fetchApi(`${path}`);
    const result = data.map(({ clave, nombre }) => ({ value: clave, label: nombre }))
    setEstado(result);
  }, [path]);

  return getEstado;
};

export const useGetSepomexMunicipio = (path,idEstado) => {
  const [getMunicipio, setMunicipio] = useState({
  });

  useEffect(async () => {
    const data = await fetchApi(`${path}/${idEstado}`);
    const result = data.map(({ id, nombre }) => ({ value: id, label: nombre }))
    setMunicipio(result);
  }, [path]);

  return getMunicipio;
};



export const useGetCallCenters = (path) => {
  const [getCallCenters, setCallCenters] = useState({
  });

  useEffect(async () => {
    const data = await fetchApi(`${path}`);    
    let CallCenters =  Object.keys(data);
    CallCenters = CallCenters.map((row) => ({ value: row, label: row }));
    const res = { CallCenters , SelectCallCenter:data}
    setCallCenters(res);
  }, [path]);

  return getCallCenters;
};

export const useGetCallCentersLogs = (agent) => {
  const [getCallCentersLogs, setCallCentersLogs] = useState();

  useEffect(async () => {
    const res = await fetchApi(`/call-center/list`);
    setCallCentersLogs(res);
  }, [agent]);

  return getCallCentersLogs;
};