/* *
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
// Images
import bgImage from "assets/images/background.jpg";
import BasicLayout from "layouts/authentication/components/BasicLayout";
import { AuthContext } from "auth/AuthContext";
import { useContext, useState } from 'react';
import { fetchSinToken,fetchApi } from "helpers/fetch";
import { useFormik } from 'formik';
import Swal from "sweetalert2";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import MDAlert from "components/MDAlert";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';

function Cover() {
  
  const navigate = useNavigate(); 
  const [form, setForm] = useState({error:false})
  const {updatePasword} = useContext(AuthContext);
  const formik = useFormik({
      initialValues: {
          email: "",
      },
      enableReinitialize: true,
      validateOnChange: true,
      //validationSchema: validationSchema,
      
      onSubmit: (values,actions) => {
          handleSubmit(values,actions);
      }
  });
  
  const handleSubmit = async (values, actions) => {
    const {ok} = await fetchSinToken(`login/search?email=${formik.values.email}`);
    if(ok){
      setForm({error:false});
      Swal.fire({
        title: '¡Favor de Actualizar su contraseña!',
        // html: `Favor de recordar esta contraseña, para poder iniciar sesión en este sistema.`,
        html: `
        <input autocomplete="off" class="swal2-input" id="password1" type="password" placeholder="Escriba su contraseña..." /><br />
        <input autocomplete="off" class="swal2-input" id="password2" type="password" placeholder="Confirme su contraseña..." />
        `,
        focusConfirm: false,
        inputAttributes: {
          autocapitalize: 'off',
          autocomplete: 'off'
        },
        showCancelButton: true,
        confirmButtonText: 'Actualizar',
        showLoaderOnConfirm: true,
        preConfirm: (password) => {
          const password1 = Swal.getPopup().querySelector('#password1').value;
          const password2 = Swal.getPopup().querySelector('#password2').value;
          if(password1 == password2){
            return updatePasword( formik.values.email,password1 )
            .then(response => {
              if (!response) {
                throw new Error(`Hubo un error al crear el usuario, favor de reportarlo a soporte@iurdsys.net`)
              }
              return response;
            })
            .catch(error => {
              Swal.showValidationMessage("error")
            })
          }else{
            Swal.showValidationMessage(`Las contraseñas no son iguales, favor de validar...`);
          }

        },
        allowOutsideClick: () => !Swal.isLoading()
      }).then(({isConfirmed,value}) => {
        if (isConfirmed) {
          navigate(`/`);
          Swal.fire({
            title: '¡Bienvenido...!', 
            html: `Acceso concedido a:<p>
                <ul style="text-align: left; ">
                  <li>Nombre:${value.name}</li>
                  <li>correo:${value.email}</li>
                </ul>
                  `,
            timer: 5000,
            timerProgressBar: true,
            didOpen: () => {
              Swal.showLoading()
              const b = Swal.getHtmlContainer().querySelector('b')
            }
          }).then((result) => {
            if (result.dismiss === Swal.DismissReason.timer) {
              console.log('I was closed by the timer')
            }
          });
        } 
      }) 
    }else{
      setForm({error:true});
    }

    actions.setSubmitting(false);
  };
    
  return (
      <BasicLayout image={bgImage}>
        <Card>
          <MDBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            coloredShadow="success"
            mx={2}
            mt={-3}
            py={2}
            mb={1}
            textAlign="center"
          >
            <MDTypography variant="h3" fontWeight="medium" color="white" mt={1}>
              Recuperar Contraseña
            </MDTypography>
            <MDTypography display="block" variant="button" color="white" my={1}>
              Favor de guardar su contraseña
            </MDTypography>
          </MDBox>

          <MDBox pt={4} pb={3} px={3}>
            {form.error && (<MDAlert color="error" dismissible>
              <MDTypography variant="body2" color="white">
                <MDTypography component="a" href="#" variant="body2" fontWeight="medium" color="white">
                  No se encontro el correo, favor de validar.
                </MDTypography>
              </MDTypography>
            </MDAlert>)}

            <form onSubmit={formik.handleSubmit}>
              <MDBox mb={4}>

                <FormField type="email" label="Correo" variant="standard" name="email" onChange={formik.handleChange} value={formik.values.email} />
              </MDBox>
              <MDBox mt={6} mb={1}>
                <MDButton variant="gradient" color="info" fullWidth type="submit" >
                  Cambiar contraseña
                </MDButton>
              </MDBox>
              <MDBox mt={3} mb={1} textAlign="center">
                  <MDTypography
                    component={Link}
                    to="/"
                    variant="button"
                    color="info"
                    fontWeight="medium"
                    textGradient
                  >
                    Iniciar sesión {" "}
                  </MDTypography>
              </MDBox>              
            </form>
          </MDBox>
        </Card>
      </BasicLayout>
  );
}

export default Cover;
