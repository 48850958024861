/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/* eslint-disable react/prop-types */
// ProductsList page components
import IdCell from "layouts/ecommerce/orders/order-list/components/IdCell";
import DefaultCell from "layouts/ecommerce/orders/order-list/components/DefaultCell";
import StatusCell from "layouts/ecommerce/orders/order-list/components/StatusCell";
import CustomerCell from "layouts/ecommerce/orders/order-list/components/CustomerCell";

import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import { NavLink } from 'react-router-dom';
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

const MySwal = withReactContent(Swal);
const dataTableData = (data) => ({
  columns: [
    { Header: "extension", accessor: "extension", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Usuario", accessor: "usuario", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Grupo", accessor: "grupo", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Call Center", accessor: "callCenter", Cell: ({ value }) => <DefaultCell value={value} /> },
    { Header: "Contraseña Asignada", accessor: "contraseñaAsignada", Cell: ({ value }) => <DefaultCell value={value} /> },
    {
      Header: "Estatus",
      accessor: "isLogged",
      Cell: ({ value }) => {
        let status;
        
        if (value) {
          status = <StatusCell icon="lock" color="success" status="Contraseña Usada" />;
        } else{
          status = <StatusCell icon="lock_clock" color="error" status="Contraseña No usada" />;
        }

        return status;
      },
    },    
    {
      Header: "Acciones",
      accessor: "status",
      Cell: ({ row }) => {
        let status;
        /* console.log(row.values) */
        if (row.values.status == false) {
          status = <MDButton 
          size="small" 
          spacing={3} 
          color="error" 
          onClick={(value) => {
            MySwal.fire({
              title: "Dar Salida",
              input: "select",
              inputOptions: {
                "TODO BIEN": "TODO BIEN",
                "ENTREGO EQUIPO EN MALAS CONDICIONES": "ENTREGO EQUIPO EN MALAS CONDICIONES",
                "MAL COMPORTAMIENTO": "MAL COMPORTAMIENTO",
              },
              inputPlaceholder: "Seleccione una opción",
              showCancelButton: true,
              inputValidator: (value) =>
                new Promise((resolve) => {
                  if (value) {
                    resolve();
                  } else {
                    resolve("Favor de seleccionar una opción :)");
                  }
                }),
            }).then((res) => {
              if (res.isConfirmed) {
                 console.log(row.values.id)

              }
            });
          }}
          >Dar Salida&nbsp;<Icon>edit_icon</Icon></MDButton>;
        }else{
          status =<StatusCell icon="exit_to_app" color="warning" status="Este Agente ya no esta activo.." />;
        }
        return status;
      },
    },
      
  ],
  rows: data,
});

export default dataTableData;