/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */

import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import TimelineItem from "examples/Timeline/TimelineItem";

function LogCall({ callsLog }) {
  return (
    <>
      <MDTypography variant="h6" fontWeight="medium">
        Registro de Actividades
      </MDTypography>
      <MDBox mt={2}>
        {callsLog.map((log) => (
          <TimelineItem
            key={log._id}
            color={(() => {
              switch (log.CallType) {
                case "Inbound":
                  return "success";
                case "Missed":
                  return "error";
                case "Outbound":
                  return "info";
                case "Notanswered":
                  return "warning";
                case "note":
                  return "info";
                case "blacklist":
                  return "error";                  
                default:
                  return null;
              }
            })()}
            icon={(() => {
              switch (log.CallType) {
                case "Inbound":
                  return "phone-check";
                case "Missed":
                  return "phone_missed";
                case "Outbound":
                  return "phone_forwarded";
                case "Notanswered":
                  return "phone_in_talk";
                case "note":
                  return "sticky_note_2";
                case "blacklist":
                  return "phone_disabled";                                      
                default:
                  return null;
              }
            })()}
            title={(() => {
              switch (log.CallType) {
                case "Inbound":
                  return "Llamada Entrante";
                case "Missed":
                  return "Llamada Perdida";
                case "Outbound":
                  return "Llamada Saliente";
                case "Notanswered":
                  return "No Contesto";
                case "note":
                  return `Nota Agregada por ${log.Agent}-${log.agent_name}`;
                case "blacklist":
                  return `Número reportado por ${log.Agent}-${log.agent_name}`;                  
                default:
                  return null;
              }
            })()}
            description={(() => {
              switch (log.CallType) {
                case "note":
                  return `${log.note}` ;  
                case "blacklist":
                  return  `${log.note}` ;                  
                default:
                  return  `Atendio agente:${log.Agent} -  ${log.AgentFirstName} ${log.AgentLastName}`;
              }
            })()}
            dateTime={log.CallStartTimeLocal}
          />
        ))}
      </MDBox>
    </>
  );
}

export default LogCall;
