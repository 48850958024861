/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

/** 
  All of the routes for the Material Dashboard 2 PRO React are added here,
  You can add a new route, customize the routes and delete the routes here.

  Once you add a new route on this file it will be visible automatically on
  the Sidenav.

  For adding a new route you can follow the existing routes in the routes array.
  1. The `type` key with the `collapse` value is used for a route.
  2. The `type` key with the `title` value is used for a title inside the Sidenav. 
  3. The `type` key with the `divider` value is used for a divider between Sidenav items.
  4. The `name` key is used for the name of the route on the Sidenav.
  5. The `key` key is used for the key of the route (It will help you with the key prop inside a loop).
  6. The `icon` key is used for the icon of the route on the Sidenav, you have to add a node.
  7. The `collapse` key is used for making a collapsible item on the Sidenav that contains other routes
  inside (nested routes), you need to pass the nested routes inside an array as a value for the `collapse` key.
  8. The `route` key is used to store the route location which is used for the react router.
  9. The `href` key is used to store the external links location.
  10. The `title` key is only for the item with the type of `title` and its used for the title text on the Sidenav.
  10. The `component` key is used to store the component of its route.
*/

// Material Dashboard 2 PRO React layouts
// import Analytics from "layouts/dashboards/analytics";
import Sales from "layouts/dashboards/sales";
import ProfileOverview from "layouts/pages/profile/profile-overview";
import Settings from "layouts/pages/account/settings";
import DetailCalls from "layouts/detailCalls";
import SignInBasic from "layouts/authentication/sign-in/basic";
import ResetPassword from "layouts/authentication/reset-password/cover";
import ListCalls from "layouts/callCenter/";
import AdminCallCenter from "layouts/callCenter/adminCallCenterForm";
import SearchPhone from "layouts/detailCalls/SearchPhone";

// Material Dashboard 2 PRO React components
import MDAvatar from "components/MDAvatar";

// @mui icons
import Icon from "@mui/material/Icon";

// Images
import profilePicture from "assets/images/photo-user-blank.jpg";
import Wizard from './layouts/applications/wizard/index';

const name = localStorage.getItem("name");
const routes = [
  {
    auth: true,
    type: "collapse",
    name: name,
    key: "login-name",
    icon: <MDAvatar src={profilePicture} alt="Brooklyn " size="sm" />,
    collapse: [
/*       {
        auth: true,
        name: "My Profile",
        key: "my-profile",
        route: "/pages/profile/profile-overview",
        component: <ProfileOverview />,
      },
      {
        auth: true,
        name: "Settings",
        key: "profile-settings",
        route: "/pages/account/settings",
        component: <Settings />,
      }, */
      {
        auth: false,
        name: "Cerrar Sesión",
        key: "logout",
        route: "/logout",
        component: <SignInBasic />,
      },
      {
        auth: false,
        name: "Cambiar Contraseña",
        key: "reset-password",
        route: "/reset-password",
        component: <ResetPassword />,
      },      
    ],
  },
  { type: "divider", key: "divider-0" },
  {
    auth: true,
    isAdmin: true,
    type: "collapse",
    name: "Dashboards",
    key: "dashboards",
    icon: <Icon fontSize="medium">dashboard</Icon>,
    collapse: [
      /*       {
        name: "Analytics",
        key: "analytics",
        route: "/dashboards/analytics",
        component: <Analytics />,
      }, */
      {
        isAdmin: true,
        auth: true,
        name: "Reportes",
        key: "sales",
        route: "/report",
        component: <Sales />,
      },
    ],
  },

  {
    auth: true,
    isAdmin: true,
    type: "collapse",
    name: "Call Centers",
    key: "call-center",
    route: "/call-center/",
    component: <AdminCallCenter />,
    icon: <Icon fontSize="medium">business</Icon>,
    noCollapse: true,
  },
  {
    auth: true,
    type: "collapse",
    name: "Ultimas Llamadas",
    key: "call-show",
    route: "/list-calls/",
    component: <ListCalls />,
    icon: <Icon fontSize="medium">contact_phone</Icon>,
    noCollapse: true,
  },
  {
    auth: true,
    type: "collapse",
    name: "Buscar número",
    key: "search-number",
    route: "/",
    component: <SearchPhone />,
    icon: <Icon fontSize="medium">phone</Icon>,
    noCollapse: true,
  },
/*   {
    auth: true,
    type: "collapse",
    name: "Buscar número",
    key: "wizard",
    route: "/wizard",
    component: <Wizard />,
    icon: <Icon fontSize="medium">phone</Icon>,
    noCollapse: true,
  },  */ 
];

export default routes;
