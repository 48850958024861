import React, {useState} from "react";
import {
    Box,
    Button,
    Card,
    FormControl, FormControlLabel, FormLabel,
    Grid,
    Input,
    MenuItem, Radio, RadioGroup,
    TextField
} from "@mui/material";
import * as yup from "yup";
import { useFormik , ErrorMessage} from "formik";
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";
import MDButton from "components/MDButton";
import Icon from "@mui/material/Icon";
import Select from "react-select" ;
import { useGetCallCenters } from '../../hooks/useGetSepomex';
import { fetchApi } from "../../helpers/fetch";
import Swal from "sweetalert2";

export default function formUserCallCenter({handleAddAgent}) {
    // {getCallCenters, SelectCallCenter}  ;
    const CallCentersData = useGetCallCenters('call-center/catalog');
    const [token, setToken] = useState("");
    const [userCallCenter, setuserCallCenter] = useState({
        agentName: "", 
        callcenter: null, 
        extension: null, 
        group: "", 
        token: "", 
    });
    
    const [CallCenters, setCallCenters] = useState({});
    
    
    const validationSchema = yup.object({
        agentName: yup.string().required('Favor de escribir el nombre completo'),
        callcenter: yup.string().required('Favor de escribir el callcenter'),
        extension: yup.string().required('Favor de seleccionar la extensión'),
        group: yup.string().required('Favor de escribir el Grupo al que pertenece'),
        token: yup.number().required('Favor de generar un token'),
    });

    const formik = useFormik({
        initialValues: {
            agentName: userCallCenter?.agentName ?? "",
            callcenter: userCallCenter?.callcenter ?? "",
            extension: userCallCenter?.extension ?? null,
            group: userCallCenter?.group ?? null,
            token: userCallCenter?.token ??  "",
            // action: (userCallCenter?.checkIn && 'checkIn') || (userCallCenter.checkOut && 'checkOut') || ''
        },
        enableReinitialize: true,
        validateOnChange: true,
        validationSchema: validationSchema,
        
        onSubmit: (values,actions) => {
            handleSubmit(values,actions);
        }
    });

    const handleSubmits = (values) => {
        const params = {
            user: values.user,
        };

        params[values.action] = new Date();
        /* 
        if (id) {
            params.id = id;
            dispatch(AttendanceActions.updateAttendance(params));
        } else {
            dispatch(AttendanceActions.createAttendance(params));
        } */
    };
    const handleSubmit = async (values, actions) => {
        Swal.fire({
        title: `¿Usted quiere crear un acceso para: ${formik.values.agentName} ?`,
        html: `La contraseña asignada es: ${formik.values.token}` ,
        showDenyButton: true,
        confirmButtonText: 'Guardar',
        denyButtonText: `Cancelar`,
        }).then(async(result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
            const result = await fetchApi(`/call-center/agent`, values, "POST");
            if(result._id) handleAddAgent(values);
        } else if (result.isDenied) {
            Swal.fire('Los cambios no fueron guardados', '', 'info')
        }
        })   
        actions.setSubmitting(false);
      };    
    const handleChangeCallCenter = ({value}) => {
        
        let password = Math.floor(1000 + Math.random() * 9000);
        formik.setFieldValue("token", password);
        setToken(password);
        setCallCenters(CallCentersData.SelectCallCenter[value]);
    }
    
    const styles = {
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? 'white' : 'grey',
          paddingTop: 0,
          paddingBottom: 0,
        }),
        input: (provided, state) => ({
          ...provided,
          padding: -10,
          paddingTop: 0,
          paddingBottom: 0,
        })
      };
      
    const Groups = [
        {
            value:"GRUPO JOVEN",
            label:"GRUPO JOVEN"
        },
        {
            value:"CALEB",
            label:"CALEB"
        },
        {
            value:"OBREROS",
            label:"OBREROS"
        },
        {
            value:"FTU",
            label:"FTU"
        },
        {
            value:"Evangelización",
            label:"Evangelización"
        },
        {
            value:"Aux. Pastor",
            label:"Aux. Pastor"
        },
        {
            value:"UEC",
            label:"UEC"
        },
        {
            value:"IBURD",
            label:"IBURD"
        },
        {
            value:"Esposa de Pr",
            label:"Esposa de Pr"
        },
        {
            value:"Pastor",
            label:"Pastor"
        },
        {
            value:"EBI",
            label:"EBI"
        },
        {
            value:"SOS",
            label:"SOS"
        },
        {
            value:"UNISOCIAL",
            label:"UNISOCIAL"
        },
        {
            value:"SOCIO EDUCATIVO",
            label:"SOCIO EDUCATIVO"
        }
        
    ];
      
    return (

        <form onSubmit={formik.handleSubmit}>
            {/*                             <TextField
                fullWidth
                select
                sx={{ mb: 2 }}
                label="Users"
                name="user"
                value={formik.values.user}
                onChange={formik.handleChange}>
                {users.map((item, i) => (
                    <MenuItem key={i} value={item._id}>{item.name}</MenuItem>
                ))}
            </TextField> */}
    
            <MDBox mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <FormField
                        type="text"
                        label="Nombre Completo"
                        name="agentName"
                        value={formik.values.agentName}
                        onChange={formik.handleChange}
                        />
                        <MDBox mt={0.75}>
                            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
                            {/* <ErrorMessage name="email" /> */}
                            </MDTypography>
                        </MDBox>                        
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select styles={styles} options={Groups}
                            onChange={(selectedOption)=>{
                                handleChangeCallCenter(selectedOption);
                                formik.setFieldValue("group", selectedOption.value);
                            }} 
                            name="group" placeholder="Grupo" />
                        </FormControl>
                    </Grid>                    
                </Grid>
            </MDBox>
            <MDBox mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6} >
                        <FormControl fullWidth>
                            {/* <InputLabel id="demo-simple-select-label">Age</InputLabel> */}
                            <Select styles={styles} options={CallCentersData.CallCenters} 
                            onChange={(selectedOption)=>{
                                handleChangeCallCenter(selectedOption);
                                formik.setFieldValue("callcenter", selectedOption.value);
                            }} 
                            name="callcenter" placeholder="Call Center" />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <FormControl fullWidth>
                            <Select styles={styles} options={CallCenters} onChange={selectedOption => formik.setFieldValue("extension", selectedOption.value)} name="extension" placeholder="Extensión" />
                        </FormControl>
                    </Grid>
                </Grid>
            </MDBox>
            <MDBox mt={1}>
                <Grid container spacing={3}>
                    <Grid item xs={12} sm={6}>
                        <Input type="text" disabled value={token} name="token" />
                    </Grid>
                </Grid>
            </MDBox>                
                {/*<FormControl component="fieldset" sx={{ mt: 2, mb: 2 }}>
                <FormLabel component="legend">Entrada</FormLabel>
                <RadioGroup
                    row
                    name="action"
                    value={formik.values.action}
                    onChange={formik.handleChange}>
                    <FormControlLabel value='checkIn' control={<Radio disabled={formik.values.action === 'checkOut'} />} label='Check In' />
                    <FormControlLabel value='checkOut' control={<Radio />} label='Check Out' />
                </RadioGroup>
            </FormControl> */}
            <Grid container>
                <MDButton
                    size="small"
                    spacing={3}
                    variant="gradient"
                    color="success"
                    type="submit"
                    >
                    Dar Entrada&nbsp;
                    <Icon>save</Icon>
                </MDButton>                
            </Grid>
        </form>
    )
}