/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */

import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

import { useGetDetailCall, listAllNotes } from "hooks/useGetDetailCall";
import { useLocation } from "react-router-dom";
import { useCallback, useState, useEffect, useContext } from "react";
import LogCall from "./LogCall";
import ChurchAssigned from "./ChurchAssigned";
import Forms from "./Forms";
import FormAtention from "./Forms/FormAtention";
import Swal from "sweetalert2";
import { fetchApi } from "helpers/fetch";
import { DetailCallContext } from 'hooks/DetailCallContext';

function DetailsCalls() {
  const { search } = useLocation();
  const number = new URLSearchParams(search).get("number");
  const [getCallsLogs, setCallsLogs] = useState();
  const data = useGetDetailCall(number,getCallsLogs);
  // const { callsLog, addresses_iurd } = data;
  
  // const logs = listAllNotes(data,getCallsLogs);

  const { detailCall } = useContext(DetailCallContext);
  
  const handleNotes = async(e) =>{
    setCallsLogs(e);
  }
  useEffect(async() => {
    detailCall(number);
    const user = await fetchApi(`searchNumber?number=${number}`);
    if(user.contact.blacklisted){
      return Swal.fire({
        title: 'Este número ha sido reportado por:',
        html: `Motivo: ${user.notesLog.blacklist[0].note} <br> Reportado por: ${user.notesLog.blacklist[0].agent} - ${user.notesLog.blacklist[0].agent_name}`,
        icon: 'error',
        showConfirmButton: true,
        showCancelButton: false,
      })
    }
    if(user.contact.updatedAt != user.contact.createdAt){
      return Swal.fire({
        title: `Este número ya se ha registrado, favor de validar la información...`,
        icon: 'info',
        showConfirmButton: true,
        showCancelButton: false,
      })      
    }
  }, [])
  
  const [getChangeDireccion, setChangeDireccion] = useState();
  const handleChangeDireccions = (data) =>{
    const direccion = {
      estado: data?.estado?.label,
      municipio: data?.municipio?.label,
      colonia: data?.colonia?.label,
      codigo_postal: data?.colonia?.codigo_postal,
    };
    return direccion
  }
 
  const handleChangeDireccion = useCallback( (data) => {

    const direccion = {
      estado: data?.estado?.label,
      municipio: data?.municipio?.label,
      colonia: data?.colonia?.label,
      codigo_postal: data?.colonia?.codigo_postal,
      address: data.address,
      book:data.book
    };
    console.log(direccion)
    setChangeDireccion( direccion )
}, [ setChangeDireccion ]);
  
  
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={1}>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={12} lg={12}>
            <Card>
              <MDBox pt={1} pb={3} px={2}>
                <MDBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={6} lg={4}>
                      <Forms data={data} Component={FormAtention} handleNotes={handleNotes} getChangeDireccion={getChangeDireccion}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} sx={{ ml: "auto" }}>
                      <ChurchAssigned data={data} handleChangeDireccion={handleChangeDireccion}/>
                    </Grid>
                    <Grid item xs={12} md={6} lg={2}>
                      <LogCall callsLog={data.callsLog} />
                    </Grid>
                  </Grid>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default DetailsCalls;
