/**
=========================================================
* Material Dashboard 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-pro-react
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Sales dashboard components
import ProductCell from "layouts/dashboards/sales/components/ProductCell";
import RefundsCell from "layouts/dashboards/sales/components/RefundsCell";
import DefaultCell from "layouts/dashboards/sales/components/DefaultCell";

// Images
import nikeV22 from "assets/images/ecommerce/blue-shoe.jpeg";
import businessKit from "assets/images/ecommerce/black-mug.jpeg";
import blackChair from "assets/images/ecommerce/black-chair.jpeg";
import wirelessCharger from "assets/images/ecommerce/bang-sound.jpeg";
import tripKit from "assets/images/ecommerce/photo-tools.jpeg";

const dataTableData = {
  columns: [
    { Header: "Agentes", accessor: "product", width: "55%" },
    { Header: "LLamadas", accessor: "value" },
  ],

  rows: [
    {
      product: (
        <ProductCell
          image={nikeV22}
          name="Maribel Mackan Cordero - Obrera Cuernavaca (Cuautla)"
          orders={9072}
        />
      ),
      value: <DefaultCell>150</DefaultCell>,
      adsSpent: <DefaultCell>$9.500</DefaultCell>,
      refunds: <RefundsCell value={13} icon={{ color: "success", name: "keyboard_arrow_up" }} />,
    },
    {
      product: (
        <ProductCell
          image={businessKit}
          name="Jesús Ángel Bautista Cruz-Aux. Cuernavaca (Cuautla)"
          orders={9077}
        />
      ),
      value: <DefaultCell>105</DefaultCell>,
      adsSpent: <DefaultCell>$4.200</DefaultCell>,
      refunds: <RefundsCell value={40} icon={{ color: "error", name: "keyboard_arrow_down" }} />,
    },
    {
      product: (
        <ProductCell
          image={blackChair}
          name="Maria Imelda Reyes Reyes-Obrera Saltillo (Coahuila)"
          orders={9212}
        />
      ),
      value: <DefaultCell>95</DefaultCell>,
      adsSpent: <DefaultCell>$9.430</DefaultCell>,
      refunds: <RefundsCell value={54} icon={{ color: "success", name: "keyboard_arrow_up" }} />,
    },
    {
      product: (
        <ProductCell
          image={wirelessCharger}
          name="MARIA BALTAZAR GARCIA-Esp. Regional Coahuila (Saltillo)"
          orders={1638}
        />
      ),
      value: <DefaultCell>83</DefaultCell>,
      adsSpent: <DefaultCell>$7.364</DefaultCell>,
      refunds: <RefundsCell value={5} icon={{ color: "error", name: "keyboard_arrow_down" }} />,
    },
    {
      product: (
        <ProductCell
          image={tripKit}
          name="Marco Miguel Quiroz Bucio-Pr. Titular Morelia (Lazaro Cardenas)"
          orders={1850}
        />
      ),
      value: <DefaultCell>75</DefaultCell>,
      adsSpent: <DefaultCell>$20.531</DefaultCell>,
      refunds: <RefundsCell value={121} icon={{ color: "success", name: "keyboard_arrow_up" }} />,
    },
  ],
};

export default dataTableData;
