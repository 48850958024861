import { useState, useEffect, useContext } from "react";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React examples
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "examples/Tables/DataTable";
import { fetchApi } from "helpers/fetch";
// Data
import dataTableData2 from "./dataTableData";
import { AuthContext } from "auth/AuthContext";

function OrderList() {
  
  const { auth } = useContext(AuthContext);
  const [menu, setMenu] = useState(null);
  const [DataTabl, setDataTabl] = useState({
    columns: [
      { Header: "Numero"},
      { Header: "Contacto"},
      { Header: "Fecha de la llamada" },
      { Header: "Tipo de llamada" },
      { Header: "Extension" },
      { Header: "Nombre del Agente" },
      { Header: "Cargo"},
      { Header: "Acciones"},
    ],
  
    rows: [],
  });
  const openMenu = (event) => setMenu(event.currentTarget);
  const closeMenu = () => setMenu(null);

  useEffect(async () => {
    const ext = localStorage.getItem("agent");
    const dataLog = await fetchApi(`/list-calls?isAdmin=${auth.isAdmin ?? false}&ext=${ext}`);
    const dt = await dataTableData2(dataLog);
    console.table(dt);
    setDataTabl(dt);
  }, []);
  
  const renderMenu = (
    <Menu
      anchorEl={menu}
      anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
      transformOrigin={{ vertical: "top", horizontal: "left" }}
      open={Boolean(menu)}
      onClose={closeMenu}
      keepMounted
    >
      <MenuItem onClick={closeMenu}>Tipo de llamada: Paid</MenuItem>
      <MenuItem onClick={closeMenu}>Tipo de llamada: Refunded</MenuItem>
      <MenuItem onClick={closeMenu}>Tipo de llamada: Canceled</MenuItem>
      <Divider sx={{ margin: "0.5rem 0" }} />
      <MenuItem onClick={closeMenu}>
        <MDTypography variant="button" color="error" fontWeight="regular">
          Remove Filter
        </MDTypography>
      </MenuItem>
    </Menu>
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <MDBox my={3}>
        <MDBox display="flex" justifyContent="space-between" alignItems="flex-start" mb={2}>
          <MDBox display="flex">
{/*             <MDButton variant={menu ? "contained" : "outlined"} color="dark" onClick={openMenu}>
              filters&nbsp;
              <Icon>keyboard_arrow_down</Icon>
            </MDButton>
           {renderMenu}
             <MDBox ml={1}>
              <MDButton variant="outlined" color="dark">
                <Icon>description</Icon>
                &nbsp;export csv
              </MDButton>
            </MDBox> */}
          </MDBox>
        </MDBox>
        <Card>
          <DataTable table={DataTabl} entriesPerPage={false} canSearch />
        </Card>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default OrderList;
