/* eslint-disable no-var */
/* eslint-disable no-shadow */
/* eslint-disable no-undef */
/* eslint-disable react/prop-types */
// import React, { useEffect, useState } from "react";
import MDBox from "components/MDBox";
// import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDTypography from "components/MDTypography";
import { ErrorMessage } from "formik";
import MDButton from "components/MDButton";
import { Switch } from "@mui/material";
import { Link } from "react-router-dom";

function BasicForm({
  handleChange,
  handleSubmit,
  isSubmitting,
  resetForm, 
  values,
  errors,
  touched,
  isValid,
}) {
  /*   const [rememberMe, setRememberMe] = useState(false);
  useEffect(() => {
    const email = localStorage.getItem("email");
    if (email) {
      setRememberMe(true);
    }
  }, []); */

  // const toggleCheck = () => setRememberMe(!rememberMe);

  return (
    <form onSubmit={handleSubmit}>
      {(values.optionLogin === "isAgent" || values.optionLogin === "admin") && (
        <MDBox mb={2}>
          <MDInput
            type="email"
            label="Correo"
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            fullWidth
          />
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              <ErrorMessage name="email" />
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
      {values.optionLogin === "notAgent" && (
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Contraseña"
            id="token"
            name="token"
            value={values.token}
            onChange={handleChange}
            fullWidth
          />
          {/*<MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              <ErrorMessage name="name" />
            </MDTypography>
          </MDBox> */}
        </MDBox>
      )}
      {(values.optionLogin === "isAgent") && (
        <MDBox mb={2}>
          <MDInput
            type="text"
            label="Extensión"
            id="extension"
            name="extension"
            value={values.extension}
            onChange={handleChange}
            fullWidth
          />
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              <ErrorMessage name="extension" />
            </MDTypography>
          </MDBox>
        </MDBox>
      )}
{/*       {values.optionLogin === "admin" && (
        <MDBox mb={2}>
          <MDInput
            type="password"
            label="Password"
            id="password"
            name="password"
            value={values.password}
            onChange={handleChange}
            fullWidth
          />
          <MDBox mt={0.75}>
            <MDTypography component="div" variant="caption" color="error" fontWeight="regular">
              <ErrorMessage name="password" />
            </MDTypography>
          </MDBox>
        </MDBox>
      )} */}

{/*       <MDBox display="flex" alignItems="center" ml={-1}>
        <Switch checked={values.rememberMe} name="rememberMe" onChange={handleChange} />
        <MDTypography
          variant="button"
          fontWeight="regular"
          color="text"
          onClick={handleChange}
          sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
        >
          &nbsp;&nbsp;Remember me
        </MDTypography>
      </MDBox> */}
      <MDBox mt={3} mb={1} textAlign="center">
        <MDButton variant="gradient" color="info" disabled={!isValid} fullWidth type="submit">
          Entrar al sistema
        </MDButton>
      </MDBox>
      <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography
            component={Link}
            to="/reset-password"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Olvido su contraseña? {" "}
          </MDTypography>
      </MDBox>
    </form>
  );
}

export default BasicForm;
